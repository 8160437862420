/** @jsxImportSource @emotion/react */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { l_projects } from "../../locales";
import BigCaseStudies from "./BigCaseStudies";
import SmallCaseStudy from "./SmallCaseStudy";

//@ts-ignore
import logo1 from "../../images/logo-1.png";
//@ts-ignore
import logo2 from "../../images/logo-2.png";
//@ts-ignore
import logo3 from "../../images/logo-3.png";
//@ts-ignore
import logo4 from "../../images/logo-4.png";
//@ts-ignore
import logo5 from "../../images/logo-5.png";
//@ts-ignore
import logo6 from "../../images/joule-logo.svg";
//@ts-ignore
import imgSrcNowr from "../../images/case-study-1.png";
//@ts-ignore
import imgSrcIsatra from "../../images/case-study-2.png";
//@ts-ignore
import imgSrcBonusX from "../../images/case-study-3.png";
//@ts-ignore
import sgeeksLogo from "../../images/sgeeks-logo.svg";
//@ts-ignore
import imgSrcSgeeks from "../../images/case-study-4.png";
//@ts-ignore
import imgSrcBelvillage from "../../images/case-study-5.png";
//@ts-ignore
import districtLogo from "../../images/district-logo.png";
//@ts-ignore
import districtImage from "../../images/district-image.jpg";
//@ts-ignore
import hmLogo from "../../images/hm-logo.png";
//@ts-ignore
import hmImage from "../../images/hm-image.jpg";
//@ts-ignore
import angeleyeLogo from "../../images/angeleye-logo.png";
//@ts-ignore
import angeleyeImage from "../../images/angeleye-image.jpg";
//@ts-ignore
import jouleImage from "../../images/joule-image.jpg";

const CaseStudy = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));

  const caseStudies = [
    {
      name: "district",
      imgSrc: districtImage,
      logo: { url: districtLogo, width: 88, height: 96 },
      description: l_projects("casestudy.descriptionJoule"),
      link: "/works/district",
      cta: l_projects("casestudy.cta8"),
    },
    {
      name: "holding-moda",
      imgSrc: hmImage,
      logo: { url: hmLogo, width: 206, height: 62 },
      description: l_projects("casestudy.descriptionHM"),
      link: "/works/holding-moda",
      cta: l_projects("casestudy.cta2"),
    },
    {
      name: "angeleye",
      imgSrc: angeleyeImage,
      logo: { url: angeleyeLogo, width: 280, height: 48 },
      description: l_projects("casestudy.descriptionAngeleye"),
      link: "/works/angeleye",
      cta: l_projects("casestudy.cta7"),
    },
    {
      name: "joule",
      imgSrc: jouleImage,
      logo: { url: logo6, width: 190, height: 44 },
      description: l_projects("casestudy.descriptionDistrict"),
      link: "/works/joule",
      cta: l_projects("casestudy.cta6"),
    },
    {
      name: "nowr",
      imgSrc: imgSrcNowr,
      logo: { url: logo1, width: 374, height: 118 },
      description: l_projects("casestudy.description1"),
      link: "/works/nowr",
      cta: l_projects("casestudy.cta1"),
    },
    {
      name: "isatra",
      imgSrc: imgSrcIsatra,
      logo: { url: logo2, width: 535, height: 118 },
      description: l_projects("casestudy.description2"),
      link: "/works/intesa-sanpaolo-assicura",
      cta: l_projects("casestudy.cta2"),
    },
    {
      name: "bonusx",
      imgSrc: imgSrcBonusX,
      logo: { url: logo3, width: 373, height: 118 },
      description: l_projects("casestudy.description3"),
      link: "/works/bonusx",
      cta: l_projects("casestudy.cta3"),
    },
    {
      name: "sgeeks",
      imgSrc: imgSrcSgeeks,
      logo: { url: sgeeksLogo, width: 188, height: 48 },
      description: l_projects("casestudy.description4"),
      link: "/works/startup-geeks/",
      cta: l_projects("casestudy.cta4"),
    },
    {
      name: "belvillage",
      imgSrc: imgSrcBelvillage,
      logo: { url: logo5, width: 150, height: 32 },
      description: l_projects("casestudy.description5"),
      link: "/works/belvillage/",
      cta: l_projects("casestudy.cta5"),
    },
  ].filter((cs) => !!cs) as CaseStudyItem[];

  return (
    <Box>
      {isOverMd && <BigCaseStudies caseStudies={caseStudies} />}
      {!isOverMd &&
        caseStudies.map((cs) => (
          <SmallCaseStudy caseStudy={cs} key={cs.name} />
        ))}
    </Box>
  );
};

export default CaseStudy;

export interface CaseStudyItem {
  name: string;
  img: ReactNode;
  imgSrc: string;
  logo: {
    url: any;
    width: number;
    height: number;
  };
  description: any;
  link: string;
  cta: any;
}

//test
