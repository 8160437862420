import { Box, Button } from "@mui/material";
import { LocalizedLink } from "../../../../components/LocalizedLink";
import ButtonDotIcon from "../../theme/ButtonDotIcon";
import ProjectsTypography from "../../theme/ProjectsTypography";
import { CaseStudyItem } from "./CaseStudy";

const SmallCaseStudy = ({ caseStudy }: { caseStudy: CaseStudyItem }) => {
  return (
    <Box
      sx={{
        width: "90%",
        maxWidth: "600px",
        margin: "auto",
        marginBottom: "48px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          aspectRatio: "1 / 1",
          backgroundImage: `url(${caseStudy.imgSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "50% 50% 40px 40px",
          marginBottom: "22px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            bottom: "26px",
            width: "100%",
            height: "48px",
            padding: "8px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              padding: "8px 16px",
              borderRadius: "30px",
              background: "white",
              width: "150px",
              height: "48px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height:
                  caseStudy.name === "angeleye"
                    ? "25px"
                    : caseStudy.name === "isatra"
                      ? "30px"
                      : caseStudy.name === "joule"
                        ? "35px"
                        : caseStudy.name === "belvillage"
                          ? "30px"
                          : caseStudy.name === "district"
                            ? "55px"
                            : "40px",
                aspectRatio: String(
                  caseStudy.logo.width / caseStudy.logo.height,
                ),
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundImage: `url(${caseStudy.logo.url})`,
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
      <ProjectsTypography
        variant="title"
        sx={{ fontSize: "20px", lineHeight: "28px", marginBottom: "16px" }}
      >
        {caseStudy.description}
      </ProjectsTypography>
      <Box sx={{ "> a": { textDecoration: "none !important" } }}>
        <LocalizedLink
          to={caseStudy.link}
          css={{ textDecoration: "none !important" }}
        >
          <Button
            variant="outlined"
            sx={{
              textDecoration: "none !important",
              borderColor: "#E85322",
              backgroundColor: "transparent",
              borderRadius: "56px",
              fontSize: "16px",
              lineHeight: "16px",
              fontWeight: 700,
              padding: "16px 8px",
              borderWidth: "2px",
              width: { xs: "100%", md: "auto" },
              // maxWidth: "600px",
              ":hover": {
                borderColor: "inherit",
                backgroundColor: "#E8532212",
                borderWidth: "2px",
              },
            }}
            startIcon={
              <Box sx={{ width: "fit-content" }}>
                <ButtonDotIcon />
              </Box>
            }
          >
            {caseStudy.cta}
          </Button>
        </LocalizedLink>
      </Box>
    </Box>
  );
};

export default SmallCaseStudy;
