import { DeepKeyof, language } from "../../traductions";

const traduction = {
  it: {
    seo: {
      title: "Progetti",
      description:
        "Scegliamo con cura i progetti su cui lavorare; in questa pagina trovi una selezione di case study di cui andiamo fieri.",
    },
    title: {
      overtitle: "Progetti",
      title1: "Lavoriamo solo su",
      title2: "ciò in cui crediamo",
      subtitle:
        "Scegliere con cura idee e progetti su cui lavorare ci permette di dedicarvi più tempo e risorse, ottenendo così prodotti che facciano davvero la differenza.",
    },
    casestudy: {
      description1: "Abbiamo creato un mondo di esperienze coinvolgenti",
      description2:
        "Sono stati digitalizzati i flussi assicurativi di una delle più grandi banche italiane",
      description3:
        "Siamo riusciti a sviluppare strumenti per semplificare la burocrazia italiana",
      description4:
        "Accompagniamo l’accelerazione di centinaia di startup da una piattaforma online",
      description5:
        "Abbiamo gestito il controllo domotico di appartamenti in tutto il mondo",
      descriptionJoule:
        "Abbiamo fatto user testing per una piattaforma di ricerca lavoro innovativa",
      descriptionHM:
        "Abbiamo creato uno showroom digitale ed esperenziale cucito su misura",
      descriptionAngeleye:
        "È stata perfezionata l’interazione tra dispositivi di un’app salvavita",
      descriptionDistrict:
        "Abbiamo ottimizzato il recruiting della prima piattaforma di matching tra designer e aziende",
      cta1: "Scopri NOWR",
      cta2: "Vai al case study",
      cta3: "Scopri Bonusx",
      cta4: "Scopri Startup Geeks",
      cta5: "Scopri Belvillage",
      cta6: "Scopri Joule",
      cta7: "Scopri AngelEye",
      cta8: "Scopri District",
      buttonText: "Scopri il progetto",
    },
  },
  en: {
    seo: {
      title: "Projects",
      description:
        "We carefully choose the projects to carry out; on this page you can find a selection of case studies we are proud of.",
    },
    title: {
      overtitle: "Projects",
      title1: "We only work on",
      title2: "what we believe in",
      subtitle:
        "A careful choice of the ideas and projects on which we want to work allows us to invest more time and resources and to produce products that make a real difference.",
    },
    casestudy: {
      description1: "We created a world of engaging experiences",
      description2:
        "We digitalize the insurance flow of one of the biggest Italian banks",
      description3: "We developed tools to simplify Italian bureaucracy",
      description4:
        "We assist the acceleration of hundreds of startups through an online platform",
      description5:
        "We managed the domotic control of apartments all over the world",
      descriptionJoule:
        "We did user testing for an innovative job search platform",
      descriptionHM:
        "We created a tailor-made digital and experiential showroom",
      descriptionAngeleye:
        "We improved the interaction between life-saving app devices",
      descriptionDistrict:
        "We optimized the recruiting of the first matching platform between designers and companies",
      cta1: "Discover NOWR",
      cta2: "Go to the case study",
      cta3: "Discover BonusX",
      cta4: "Discover Startup Geeks",
      cta5: "Discover Belvillage",
      cta6: "Discover Joule",
      cta7: "Discover AngelEye",
      cta8: "Discover District",
      buttonText: "Discover the project",
    },
  },
};

export const l_projects = (
  trad: DeepKeyof<(typeof traduction)[keyof typeof traduction]>,
) => {
  const tokens = trad.split(".");
  let resource = traduction && (traduction as any)[language.code];
  if (!resource) {
    return tokens[tokens.length - 1];
  }

  for (let c = 0; c < tokens.length; c++) {
    resource = resource[tokens[c]];
    if (!resource) {
      // resource not found
      return trad;
    }
  }

  return resource;
};
